import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBox = _resolveComponent("FilterBox")!
  const _component_Entitytable = _resolveComponent("Entitytable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FilterBox, {
      onChanged: _cache[0] || (_cache[0] = 
      () => {
        _ctx.$refs.pingo.resetPage();
        _ctx.$refs.pingo.retrieveData();
      }
    ),
      applied_filters: _ctx.filters,
      show_tz: true,
      show_publisher: false,
      show_callcenter: false,
      show_vertical: false,
      show_offer: false,
      show_country: false
    }, null, 8, ["applied_filters"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Entitytable, {
          ref: "revisionTable",
          "entity-name": "revision",
          "resource-name": "revision",
          "filter-data": _ctx.filters,
          "show-pagination": true
        }, null, 8, ["filter-data"])
      ])
    ])
  ], 64))
}